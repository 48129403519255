import Image from 'next/image'

export default function SCP_CoinIcon({ level, className = '' }) {
  const icons = {
    min: '/images/better-club-coin.svg',
    normal: '/images/better-club-coin.svg',
    max: '/images/better-club-coin.svg',
    modal: '/images/better-club-tick.svg',
    profile: '/images/better-club-coin.svg',
    info: '/images/better-club-coin.svg',
  }
  const icon = level ? icons[level] : '/images/better-club-tick.svg'

  let size = 24
  switch (level) {
    case 'min':
      size = 24
      break
    case 'normal':
      size = 24
      break
    case 'max':
      size = 48
      break
    case 'modal':
      size = 120
      break
    case 'profile':
      size = 120
      break
    case 'info':
      size = 120
      break
    default:
      size = 24
      break
  }

  return (
    <Image
      alt="coin-icon"
      width={size}
      height={size}
      src={icon}
      className={`tw-flex-none ${className}`}
    />
  )
}
